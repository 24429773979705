import React, { useState, useContext, useEffect, useRef } from "react";
import EXIF from "exif-js";
import PopupMap from "./PopupMap";
import PopupLayout from "./PopupLayout";
import { GlobalContext } from "../../contexts/GlobalContext";
import api from "../../axios/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/NewFiles/Css/Upload.css";

const UploadComponent = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [image, setImage] = useState(null);
  const [treeName, setTreeName] = useState("");
  const [plantedOn, setPlantedOn] = useState("");
  const [error, setError] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const {
    address,
    setAddress,
    latlng,
    setLatlng,
    country,
    setCountry,
    setLoggedIn,
    isLoggedIn
  } = useContext(GlobalContext);
  const fileUploadRef = useRef(null);
  const [invalidFields, setInvalidFields] = useState([]);
  const navigate = useNavigate();


const handleImageUpload = async (e) => {
    const file = e.target.files[0];
  
    // Check if the file is an image
    if (!file || !file.type.startsWith('image/')) {
        setError("Please upload a valid image file.");
        toast.error("Please upload a valid image file.");
        e.target.value = "";
        return;
    }

    // Function to read EXIF data before compression
    const readExifData = () => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.onload = function () {
            EXIF.getData(img, function () {
              const lat = EXIF.getTag(this, "GPSLatitude");
              const lon = EXIF.getTag(this, "GPSLongitude");
              if (lat && lon) {
                console.log("Raw EXIF data - Latitude:", lat, "Longitude:", lon);
                resolve({ lat, lon, img });
              } else {
                reject(new Error("No GPS data found in EXIF"));
              }
            });
          };
          img.src = event.target.result;
        };
        reader.readAsDataURL(file);
      });
    };
  
    let exifData;
    try {
      // Try to read EXIF data for geolocation
      exifData = await readExifData();
  
      // Get reference direction (N, S, E, W)
      const latRef = EXIF.getTag(exifData.img, "GPSLatitudeRef") || "N";
      const lonRef = EXIF.getTag(exifData.img, "GPSLongitudeRef") || "W";
  
      // Calculate the latitude and longitude in decimal format
      const latitude = (exifData.lat[0] + exifData.lat[1] / 60 + exifData.lat[2] / 3600) * (latRef === "N" ? 1 : -1);
      const longitude = (exifData.lon[0] + exifData.lon[1] / 60 + exifData.lon[2] / 3600) * (lonRef === "W" ? -1 : 1);
  
      setLatlng({ lat: latitude, lng: longitude });
      // Fetch address data from the calculated coordinates
      const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&accept-language=en&lat=${latitude}&lon=${longitude}`);
      console.log(response);
      if (!response.ok) {
        throw new Error("Unable to fetch address data");
      }
      const data = await response.json();
      setAddress(data.display_name);
      setCountry(data.address.country);
  
    } catch (error) {
      // Handle specific errors for better user feedback
      if (error.message === "No GPS data found in EXIF") {
        console.warn("Warning:", error.message);
        setError("No geolocation data found in the image.");
        toast.warn("No geolocation data found in the image.");
        setAddress("");
        setCountry("");
      } else if (error.message === "Unable to fetch address data") {
        console.error("Error:", error.message);
        setError("Failed to retrieve address data from coordinates.");
        toast.error("Failed to retrieve address data from coordinates.");
        setAddress("");
        setCountry("");
      } else {
        console.error("Error processing image:", error.message);
        setError("Failed to process geolocation.");
        toast.error("Failed to process geolocation.");
        setAddress("");
        setCountry("");
      }
  
      // Set default values if geolocation fails
      setLatlng(null);
      setError("No geolocation data found in the image.");
    }
  
    try {
      // Proceed with image compression regardless of geolocation
      const options = {
        maxSizeMB: 1,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);
      setImage(compressedFile);
      toast.success("Image processed successfully!");
    } catch (compressionError) {
      console.error("Error compressing image:", compressionError.message);
      setError("Failed to compress the image.");
      toast.error("Failed to compress the image.");
    }
};

  useEffect(() => {
    // Update location when address context changes
  }, [address]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!isLoggedIn) {
      toast.error("Please log in to upload a post.");
      setTimeout(() => {
        navigate("/login"); // Redirect to the login page after delay
      }, 2000);
      return;
    }
  
    const newInvalidFields = [];
  
    if (!image) newInvalidFields.push("image");
    if (!treeName) newInvalidFields.push("treeName");
    if (!address) newInvalidFields.push("address");
    if (!plantedOn) newInvalidFields.push("plantedOn");
    if (!latlng) newInvalidFields.push("latlng");
  
    if (newInvalidFields.length > 0) {
      setInvalidFields(newInvalidFields);
      setError("Please provide all required information.");
      toast.error("Please provide all required information.");
      return;
    }
  
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Please log in to upload a post.");
      navigate("/login"); // Redirect to the login page
      setLoggedIn(false); // Update the global context
      return;
    }
  
    setIsSubmitting(true); // Disable the button
  
    const formData = new FormData();
    formData.append("plantedOn", new Date(plantedOn).toISOString());
    formData.append("Address", address);
    formData.append("Plant_name", treeName);
    formData.append("lat", latlng.lat.toString());
    formData.append("lng", latlng.lng.toString());
    formData.append("image", image); // Append the image file
    formData.append("image_name", image.name);
    formData.append("country", country);
  
    try {
      const res = await api.post("/api/upload/user", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
        },
      });
  
      toast.success(res.data.message);
  
      // Clear the context and reset form fields after submission
      setImage(null);
      setTreeName("");
      setAddress("");
      setLatlng(null);
      setPlantedOn("");
      setCountry("");
      setError("");

      window.location.reload();
    } catch (error) {
      console.error(error);
      setError("Failed to upload image or submit data.");
      toast.error("Failed to upload image or submit data.");
    } finally {
      setIsSubmitting(false); // Re-enable the button
    }
  };
  

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleFocus = (event) => {
    event.target.showPicker();
  };

  const invalidFieldStyle = {
    border: "2px solid red",
  };

  const CustomInput = ({ value, onClick, invalidFields }) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      readOnly
      placeholder="dd-mm-yyyy hh:mm"
      className={`form-control ${
        invalidFields.includes("plantedOn") ? "invalid-field" : ""
      }`}
      style={{
        cursor: "pointer",
        ...(invalidFields.includes("plantedOn")
          ? { border: "1px solid red" }
          : {}),
      }}
    />
  );

  return (
    <div>
      <div>
        <section className="bg-image pt-40 pb-40">
          <div className="container">
            <h2
              className="wow fadeInUp"
              data-wow-duration="1.2s"
              data-wow-delay=".2s"
            >
              Upload Tree
            </h2>
          </div>
        </section>

        <section className="contact pt-60 pb-60">
          <div className="container">
            <div className="row g-4">
              <div className="col-lg-6">
                <div className="content-upload  bg-image-upload">
                  <h2>Plant Upload Procedure</h2>
                  <p>
                    Kindly provide clear picture and geo location detail of
                    plant images for higher accuracy. Watch the tutorial video
                    for more information.
                  </p>
                  <ul className="upload">
                    <li>
                      <img
                        className="pe-2"
                        src="/images/icon/leaf.png"
                        alt="icon"
                        style={{ display: "inline" }}
                      />
                      On your device, enable the location service.
                    </li>
                    <li>
                      <img
                        className="pe-2"
                        src="/images/icon/leaf.png"
                        alt="icon"
                        style={{ display: "inline" }}
                      />
                      Verify that the camera has access to the location service.
                    </li>
                    <li>
                      <img
                        className="pe-2"
                        src="/images/icon/leaf.png"
                        alt="icon"
                        style={{ display: "inline" }}
                      />
                      Take pictures of plants with your phone.
                    </li>
                    <li>
                      <img
                        className="pe-2"
                        src="/images/icon/leaf.png"
                        alt="icon"
                        style={{ display: "inline" }}
                      />
                      Check the image file details in your phone's gallery to
                      see if the location is present in the captured images.
                    </li>
                    <li>
                      <img
                        className="pe-2"
                        src="/images/icon/leaf.png"
                        alt="icon"
                        style={{ display: "inline" }}
                      />
                      For fast processing, copy the original photos to the
                      computer. (Optional)
                    </li>
                    <li>
                      <img
                        className="pe-2"
                        src="/images/icon/leaf.png"
                        alt="icon"
                        style={{ display: "inline" }}
                      />
                      Upload plant photos using the file uploader provided on
                      this page.
                    </li>
                    <li>
                      <img
                        className="pe-2"
                        src="/images/icon/leaf.png"
                        alt="icon"
                        style={{ display: "inline" }}
                      />
                      Our Team will Verify the image in First Come First Serve
                      manner and will approve it for public listing
                    </li>
                  </ul>

                  <div className="col-lg-12">
                    <div className="container-youtube upload-page">
                      <iframe
                        title="ytvideo"
                        className="responsive-iframe"
                        src="https://www.youtube.com/embed/AkbGz3CYvqE?&loop=1&autoplay=0&controls=0"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-area">
                  <form onSubmit={handleSubmit}>
                      <h3>Enter all below details to upload tree</h3>
                    <input
                      type="file"
                      // accept="image/*"
                      onChange={handleImageUpload}
                      ref={fileUploadRef}
                      // capture="environment"
                      style={
                        invalidFields.includes("image") ? invalidFieldStyle : {}
                      }
                      tabIndex='1'
                    />

                    <input
                      type="text"
                      value={treeName}
                      onChange={(e) => setTreeName(e.target.value)}
                      placeholder="Tree Name"
                      style={
                        invalidFields.includes("treeName")
                          ? invalidFieldStyle
                          : {}
                      }
                      tabIndex='2'                      
                    />

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="text"
                        placeholder="Select Location From Map"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        readOnly
                        style={{
                          flex: "1",
                          marginRight: "10px",
                          ...(invalidFields.includes("address")
                            ? invalidFieldStyle
                            : {}),
                        }}
                        onFocus={openPopup}
                      />
                      <button
                        type="button"
                        className="fa-solid"
                        onClick={openPopup}
                        style={{
                          height: "60px",
                          paddingRight: "55px",
                          paddingLeft: "55px",
                          marginBottom: "20px",
                          backgroundColor: "#3E6294",
                        }}
                        tabIndex='3'
                      >
                        📍
                      </button>
                      <PopupLayout
                        component={PopupMap}
                        isOpen={isPopupOpen}
                        onClose={closePopup}
                      />
                    </div>


                    <div
                      style={{
                        display: "grid",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <DatePicker
                        tabIndex='4'
                        selected={plantedOn}
                        onChange={(date) => setPlantedOn(date)}
                        onFocus={handleFocus}
                        placeholderText="dd-mm-yyyy"
                        showTimeSelect
                        dateFormat="Pp"
                        className={`form-control ${
                          invalidFields.includes("plantedOn")
                            ? "invalid-field"
                            : ""
                        }`}
                        popperClassName="date-picker-popper"
                        popperPlacement="bottom-start"
                        customInput={
                          <CustomInput invalidFields={invalidFields} />
                        }
                      />
                    </div>
                    <button type="submit" disabled={isSubmitting}>
                      Submit
                      <i className="fa-solid fa-arrow-right-long" />
                    </button>


                    {!address && error && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          textAlign: "center",
                        }}
                      >
                        {error}
                      </p>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UploadComponent;
