import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TopUsersTable = () => {
  const [users, setUsers] = useState([]);
  const [totalCounts, setTotalCounts] = useState({ totalPosts: 0, totalOther: 0, totalUploads: 0 });

  useEffect(() => {
    const fetchTopUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/User/topUsers`);
        setUsers(response.data);

        // Calculate totals
        let totalPosts = 0;
        let totalOther = 0;
        let totalUploads = 0;

        response.data.forEach(user => {
          totalPosts += user.totalTrees;
          totalUploads += user.totalTrees;
        });

        setTotalCounts({ totalPosts, totalOther, totalUploads });
      } catch (error) {
        console.error('Error fetching top users:', error);
      }
    };

    fetchTopUsers();
  }, []);

  const styles = {
    container: {
      padding: '30px 15px',
      textAlign: 'center',
    },
    tableWrapper: {
      width: '100%',
      display: 'block',
      overflow: 'auto',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '20px',
    },
    th: {
      border: '1px solid #ddd',
      padding: '12px',
      backgroundColor: '#f2f2f2',
      fontWeight: 'bold',
      textAlign: 'left',
      fontSize: '1rem',
    },
    td: {
      border: '1px solid #ddd',
      padding: '12px',
      textAlign: 'left',
      fontSize: '1rem',
    },
    tr: {
      cursor: 'initial',
    },
    totalRow: {
      fontWeight: 'bold',
    },
    a: {
      cursor: 'initial',
      color: 'black',
      textDecoration: 'none',
    },
    img: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      marginRight: '10px',
    },
  };

  return (
    <div style={styles.container}>
      <h1>Leaders</h1>
      {/* <p>By clicking on the user name, you can reach the user profile</p> */}
      
      {/* Table wrapper */}
      <div style={styles.tableWrapper}>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Profile</th>
              <th style={styles.th}>Name</th>
              <th style={styles.th}>Plants</th>
              <th style={styles.th}>Other</th>
              <th style={styles.th}>Uploads</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index} style={styles.tr}>
                <td style={styles.td}>
                  <td style={styles.a}>
                    <img src={user.userProfilePic} alt={user.userName} style={styles.img} />
                  </td>
                </td>
                <td style={styles.td}>
                  <td
                    style={styles.a}
                  >
                    {user.userName}
                  </td>
                </td>
                <td style={styles.td}>{user.totalTrees}</td>
                <td style={styles.td}>0</td>
                <td style={styles.td}>{user.totalTrees}</td>
              </tr>
            ))}
            <tr style={styles.totalRow}>
              <td style={styles.td}>Total</td>
              <td style={styles.td}></td>
              <td style={styles.td}>{totalCounts.totalPosts}</td>
              <td style={styles.td}>{totalCounts.totalOther}</td>
              <td style={styles.td}>{totalCounts.totalUploads}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Responsive media query */}
      <style>
        {`
          @media (max-width: 768px) {
            table {
              font-size: 14px;
            }
            
            th, td {
              padding: 8px;
              font-size: 14px;
            }

            h1 {
              font-size: 24px;
            }

            img {
              width: 30px;
              height: 30px;
            }
          }

          @media (max-width: 480px) {
            table {
              font-size: 12px;
            }

            th, td {
              padding: 6px;
              font-size: 12px;
            }

            h1 {
              font-size: 20px;
            }

            img {
              width: 25px;
              height: 25px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default TopUsersTable;
