import React from 'react';
import { Outlet, useNavigate, useLocation } from "react-router-dom";

const Statistics = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const getButtonStyle = (path) => ({
    margin: '10px',
    padding: '10px 20px',
    border: 'solid 1px',
    borderRadius: '20px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    backgroundColor: location.pathname.includes(path) ? '#4CAF50' : '#f0f0f0',
    color: location.pathname.includes(path) ? 'white' : 'black',
    cursor: 'pointer',
    width: '100%',
    maxWidth: '250px',
    boxSizing: 'border-box'
  });

  const hoverStyle = {
    filter: 'brightness(85%)'
  };

  return (
    <div>
      <section className="bg-image pt-40 pb-40">
        <div className="container">
          <h2 className="wow fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">Statistics</h2>
        </div>
      </section>

      <div className="button-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px', flexWrap: 'wrap' }}>
        <button
          onClick={() => navigate("./chart")}
          style={getButtonStyle("chart")}
          onMouseEnter={(e) => e.currentTarget.style.filter = hoverStyle.filter}
          onMouseLeave={(e) => e.currentTarget.style.filter = 'none'}
        >
          Default
        </button>
        <button
          onClick={() => navigate("./geographic")}
          style={getButtonStyle("geographic")}
          onMouseEnter={(e) => e.currentTarget.style.filter = hoverStyle.filter}
          onMouseLeave={(e) => e.currentTarget.style.filter = 'none'}
        >
          Geographic
        </button>
        <button
          onClick={() => navigate("./top50users")}
          style={getButtonStyle("users")}
          onMouseEnter={(e) => e.currentTarget.style.filter = hoverStyle.filter}
          onMouseLeave={(e) => e.currentTarget.style.filter = 'none'}
        >
          Leaders
        </button>
      </div>

      <Outlet />

      {/* Responsive media query */}
      <style>
        {`
          @media (max-width: 768px) {
            .button-container {
              flex-direction: column; 
              align-items: stretch;  
            }

            button {
              width: 100%;           
              margin: 10px 0;  
            }
          }
        `}
      </style>
    </div>
  );
};

export default Statistics;
